<template>
  <div id="app">
    <nav>
      <div class="logo">
        <router-link to="/" @click.native="close_menu"><img src="./assets/images/logo.png"></router-link>
      </div>
      <div class="top-content">
        <a href="https://play.google.com/store/apps/details?id=com.fos.metaverse.overseas&pli=1" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-android2" viewBox="0 0 16 16">
            <path
              d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z" />
          </svg>
        </a>
        <a href="https://apps.apple.com/us/app/future-crosser/id6479590085" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-apple" viewBox="0 0 16 16">
            <path
              d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
            <path
              d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
          </svg>
        </a>
        <router-link to="/Appointment?productId=b384edd2-7707-11ee-b86f-00163e2ab162">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-bag-heart-fill"
           viewBox="0 0 16 16"><path d="M11.5 4v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 
           0 0 2-2V4h-3.5ZM8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1Zm0 6.993c1.664-1.711 5.825 
           1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
          </svg>
        </router-link>
        <a href="javascript:;" @click="open_menu" v-if="isShowMenu == false">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-justify" 
          viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 
          0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 
          0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </a>
        <a href="javascript:;" @click="close_menu" v-if="isShowMenu == true">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 
          1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
        </a>
      </div>
    </nav>
    <div :class="['menu',{'open-menu':isShowMenu == true}]">
      <div><router-link to="/Through">Product introduction</router-link></div>
      <div><router-link to="/CompanyProfile">Company overview</router-link></div>
      <div><router-link to="/ServicesFeatures">Services and features</router-link></div>
      <div><router-link to="/CreatorCommunity">Creators community</router-link></div>
    </div>
    <router-view/>
    <footer>
      <div class="footer-floor1">
        <div class="footer-floor1-panel1">
          <div><router-link to="/CompanyProfile">About us</router-link></div>
          <div><router-link to="/Ar">Future AR</router-link></div>
          <div><router-link to="/Sdk">Future Developer Alliance</router-link></div>
        </div>
        <div class="footer-floor1-panel2">
          <div><router-link to="/Appointment?productId=b384edd2-7707-11ee-b86f-00163e2ab162" class="t-btn wit-btn">Book now</router-link></div>
          <div><router-link to="/EnquireNow" class="t-btn wit-btn">Inquire now</router-link></div>
        </div>
     </div>
     <div class="footer-floor2">
   
     </div>
     <div class="footer-floor3">
  
    </div>
    </footer>
  </div>
</template>

<style lang="less">
@font-face {
  font-family: "hyyakuheiw";
  src: url('./font-familys/hyyakuheiw.ttf')
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  z-index: -10;
  height: auto;
  margin: auto;
}

body {
  position: relative;
  z-index: -100;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  width: 0;
}

nav {
  width: 100%;
  height: 3rem;
  background-color: black;
  opacity: 0.8;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .logo{
  height: 1.5rem;
  width: auto;

    img{
        width: auto;
        height: 100%;
    }
  }

  .top-content{
    display: flex;
    justify-content: right;
    align-items: center;
    column-gap: 1.5rem;

    a{
      color: #878787;

      &.router-link-exact-active {
        color: white;
      }
    }

    svg{
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}

.menu{
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 50;
  top: -100vh;
  left: 0;
  background-color: black;

  padding-top: 4rem;
  transition: 1s;

  div{
    // border: 1px solid red;
    width: 90vw;
    height: 7rem;
    line-height: 7rem;
    text-align: center;
    margin: auto;
    border-bottom:1PX solid #212121;
  }

  a{
    color: #bebaba;
    font-size: 1.5rem;
    text-decoration: none;

    &.router-link-exact-active {
      color: white;
    }
  }

}

.open-menu{
  top: 0;
}


.t-btn{
  text-decoration: none;
  color: white;
  border-radius: 50px;
  padding: 2px 10px;
  min-width: 120px;
}

.trans-btn{
  border: 1px solid white;
  padding: 5px 20px;
}

.change-btn{
  border: 1px solid white;
  padding: 8px 30px;
}

.wit-btn{
  border: 1px solid white;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 8rem;
  // height: 2rem;
  // padding: 2px 5px;
  //           min-width: 120px;
}

footer{
  background-color: #111111;
  color: #878787;
  text-align: left;
  padding: 2rem 0;

  .footer-floor1-panel1{
    // border: 1px solid gold;
    
    div{
      // border: 1px solid red;
      padding-left: 3rem;
      width: auto;
      height: 2.5rem;
      line-height: 2.5rem;
    }
  }

  .footer-floor1-panel2{
    // border: 1px solid gold;
    padding-top: 3rem;
    
    div{
      // border: 1px solid red;
      padding-left: 3rem;
      width: auto;
      height: 3rem;
      line-height: 3rem;
    }
  }

  a{
    color: #878787;
    text-decoration: none;

    &.router-link-exact-active {
      color: white;
      font-weight: bold;
    }
  }
}

.trunk {
  position: relative;
  z-index: -5;
  width: 100%;
  min-height: 100vh;
}

.floor {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: -1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

}

.bottom-shadow {
  background-image: url('./assets/images/video-buttom.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0.9;
}

</style>
<script>
export default {
  data() {
    return {
      isShowMenu:false
    }
  },
  created() {
    this.windowsChange();
  },
  watch:{
    '$route':{
      handler(to, from){
        if(to.name != from.name){
          this.close_menu();
        }
      }
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowsChange();
    }
    window.addEventListener("scroll", this.close_menu);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.close_menu);
  },
  methods: {
    open_menu:function(){
      this.isShowMenu = true;
    },
    close_menu:function(){
      this.isShowMenu = false;
    },
    windowsChange: function () {
      var cw = document.body.clientWidth;
      if (cw >= 850) {
        window.location.href = 'https://www.metamig.top/'
      }
    }
  }
}
</script>
